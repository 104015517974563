import { Grid } from "@material-ui/core";
import { Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  ethnicities,
  eyeColors,
  hairColors,
  races,
  regions,
  sexes,
  subjectTypes,
} from "../../constants";

import { Subject } from "../../types";
import GridCell from "../GridCell";
import Separator from "../Separator";

interface SubjectEntryProps {
  index: number;
  subject: Subject;
}

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const SubjectEntry = ({ index, subject }: SubjectEntryProps) => {
  return (
    <View>
      <Separator size="medium" />
      <StyledText category="s1">{`Subject #${index + 1}`}</StyledText>
      <Separator size="medium" />
      <Grid container spacing={2}>
        {subject.name && (
          <Grid item xs={3}>
            <GridCell description={subject.name} title="Name" />
          </Grid>
        )}
        {subject.type && (
          <Grid item xs={3}>
            <GridCell
              description={subjectTypes[subject.type]?.text}
              title="Type"
            />
          </Grid>
        )}
        {subject.description && (
          <Grid item xs={12}>
            <GridCell description={subject.description} title="Description" />
          </Grid>
        )}
        {subject.address && (
          <Grid item xs={3}>
            <GridCell description={subject.address} title="Address" />
          </Grid>
        )}
        {subject.phoneNumber && (
          <Grid item xs={3}>
            <GridCell description={subject.phoneNumber} title="Phone" />
          </Grid>
        )}
        {subject.age && (
          <Grid item xs={3}>
            <GridCell description={subject.age} title="Age" />
          </Grid>
        )}
        {subject.dateOfBirth && (
          <Grid item xs={3}>
            <GridCell description={subject.dateOfBirth} title="Date of birth" />
          </Grid>
        )}
        {subject.race && (
          <Grid item xs={3}>
            <GridCell description={races[subject.race]?.text} title="Race" />
          </Grid>
        )}
        {subject.ethnicity && (
          <Grid item xs={3}>
            <GridCell
              description={ethnicities[subject.ethnicity]?.text}
              title="Ethnicity"
            />
          </Grid>
        )}
        {subject.height && (
          <Grid item xs={3}>
            <GridCell description={subject.height} title="Height" />
          </Grid>
        )}
        {subject.weight && (
          <Grid item xs={3}>
            <GridCell description={subject.weight} title="Weight" />
          </Grid>
        )}
        {subject.hairColor && (
          <Grid item xs={3}>
            <GridCell
              description={hairColors[subject.hairColor]?.text}
              title="Hair color"
            />
          </Grid>
        )}
        {subject.eyeColor && (
          <Grid item xs={3}>
            <GridCell
              description={eyeColors[subject.eyeColor]?.text}
              title="Eye color"
            />
          </Grid>
        )}
        {subject.sex && (
          <Grid item xs={3}>
            <GridCell description={sexes[subject.sex]?.text} title="Sex" />
          </Grid>
        )}
        {subject.license && (
          <Grid item xs={3}>
            <GridCell
              description={`${subject.license.number}${
                subject.license.number ? "\n" : ""
              }${
                subject.license.region && regions[subject.license.region]?.text
              }`}
              title="Driver's license"
            />
          </Grid>
        )}
      </Grid>
      <Separator size="medium" />
    </View>
  );
};

export default SubjectEntry;
