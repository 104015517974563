import { Grid } from "@material-ui/core";
import { Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import * as geolib from "geolib";
import React, { useContext } from "react";
import styled from "styled-components/native";

import { dateTimeFormat, mapDefaultZoom } from "../../constants";
import {
  ActionItemCompletionRecord,
  PublicJob,
  TaskCompletionRecord,
} from "../../types";
import AspectRatioView from "../AspectRatioView";
import DefaultGoogleMapReact from "../DefaultGoogleMapReact";
import JobSummariesContext from "../JobSummariesContext";
import PersonDotMarker from "../PersonDotMarker";
import Separator from "../Separator";

interface ChecklistItemCompletionEntryProps {
  checklistItemCompletionRecord:
    | ActionItemCompletionRecord
    | TaskCompletionRecord;
  job: PublicJob;
}

const MapContainer = styled.View`
  border-radius: 8px;
  overflow: hidden;
`;

const MapPadding = styled.View`
  height: 100%;
  padding: 8px;
`;

const MediaContainer = styled.View`
  height: 100%;
  padding: 8px;
`;

const StyledImage = styled.Image`
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const ChecklistItemCompletionEntry = ({
  checklistItemCompletionRecord,
  job,
}: ChecklistItemCompletionEntryProps) => {
  const context = useContext(JobSummariesContext);

  const site = context.sites[job.siteId];

  return (
    <Grid container>
      <Grid item xs={checklistItemCompletionRecord.media ? 6 : 9}>
        <Separator size="medium" />
        <StyledText appearance="hint" category="s2">
          {dayjs(checklistItemCompletionRecord.record.at).format(
            dateTimeFormat
          )}
        </StyledText>
        <Separator size="small" />
        <StyledText category="s2">
          {"taskId" in checklistItemCompletionRecord
            ? job.tasks.find(
                (element) => element.id === checklistItemCompletionRecord.taskId
              )?.description
            : context.actionItems[checklistItemCompletionRecord.actionItemId]
                .description}
        </StyledText>
        <Separator size="medium" />
      </Grid>
      {checklistItemCompletionRecord.media && (
        <Grid item xs={3}>
          <MediaContainer>
            <StyledImage
              resizeMode="cover"
              source={{
                uri: context.media[checklistItemCompletionRecord.media.id].url,
              }}
            />
          </MediaContainer>
        </Grid>
      )}
      <Grid item xs={3}>
        <MapPadding>
          <MapContainer>
            <AspectRatioView height={9} width={16}>
              <DefaultGoogleMapReact
                defaultCenter={site.coordinates}
                defaultZoom={mapDefaultZoom}
                onGoogleApiLoaded={({ map, maps }) => {
                  const GoogleMapsMarker = new maps.Marker({
                    position: site.coordinates,
                  });
                  GoogleMapsMarker.setMap(map);

                  const { maxLat, maxLng, minLat, minLng } = geolib.getBounds([
                    site.coordinates,
                    checklistItemCompletionRecord.record.coordinates,
                  ]);
                  map.fitBounds({
                    east: maxLng,
                    north: maxLat,
                    south: minLat,
                    west: minLng,
                  });
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                <PersonDotMarker
                  lat={checklistItemCompletionRecord.record.coordinates.lat}
                  lng={checklistItemCompletionRecord.record.coordinates.lng}
                />
              </DefaultGoogleMapReact>
            </AspectRatioView>
          </MapContainer>
        </MapPadding>
      </Grid>
    </Grid>
  );
};

export default ChecklistItemCompletionEntry;
