import { ThemeType } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";

interface SubheaderContainerProps {
  theme: ThemeType;
}

const SubheaderContainer = styled.View<SubheaderContainerProps>`
  background-color: ${(props) =>
    props.theme["color-primary-transparent-default"]};
`;

export default SubheaderContainer;
