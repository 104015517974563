import { ThemeType, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components";

import AvoidPageBreakInsideContainer from "../AvoidPageBreakInsideContainer";
import PdfBottomRow from "./PdfBottomRow";
import PdfCenterRow from "./PdfCenterRow";
import PdfTopRow from "./PdfTopRow";

interface PdfRowProps {
  children?: React.ReactNode;
  position?: "bottom" | "center" | "top";
}

const StyledAvoidPageBreakInsideContainer = styled(
  AvoidPageBreakInsideContainer
)<{ theme: ThemeType }>`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme["border-basic-color-3"]};
  overflow: hidden;
`;

const PdfRow = ({ children, position }: PdfRowProps) => {
  const theme = useTheme();
  switch (position) {
    case "bottom": {
      return <PdfBottomRow>{children}</PdfBottomRow>;
    }
    case "center": {
      return <PdfCenterRow>{children}</PdfCenterRow>;
    }
    case "top": {
      return <PdfTopRow>{children}</PdfTopRow>;
    }
    default: {
      return (
        <StyledAvoidPageBreakInsideContainer theme={theme}>
          {children}
        </StyledAvoidPageBreakInsideContainer>
      );
    }
  }
};

export default PdfRow;
