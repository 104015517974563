import { Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { dateFormat, dateTimeFormat, timeFormat } from "../../constants";

interface TimeRangeProps {
  endDateTime: string;
  startDateTime: string;
}

const StyledText = styled(Text)`
  text-align: right;
`;

const TimeRange = ({ endDateTime, startDateTime }: TimeRangeProps) => {
  const startMoment = dayjs(startDateTime);
  const endMoment = dayjs(endDateTime);

  let firstLine: string;
  let secondLine: string;
  if (!endDateTime) {
    firstLine = dayjs(startDateTime).format(dateTimeFormat);
    secondLine = "";
  } else if (startMoment.isSame(endMoment, "day")) {
    firstLine = startMoment.format(dateFormat);
    secondLine = `from ${startMoment.format(timeFormat)} to ${endMoment.format(
      timeFormat
    )}`;
  } else {
    firstLine = `from ${startMoment.format(dateTimeFormat)}`;
    secondLine = `to ${endMoment.format(dateTimeFormat)}`;
  }
  return (
    <View>
      <StyledText>{firstLine}</StyledText>
      {Boolean(secondLine) && <StyledText>{secondLine}</StyledText>}
    </View>
  );
};

export default TimeRange;
