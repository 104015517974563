import { Grid } from "@material-ui/core";
import { Text } from "@ui-kitten/components";
import React, { Fragment, useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { PublicJob } from "../../types";
import AvoidPageBreakInsideContainer from "../AvoidPageBreakInsideContainer";
import ChecklistCompletionCard from "../ChecklistCompletionCard";
import DailyActivityReportCard from "../DailyActivityReportCard";
import EmployeeActivityCard from "../EmployeeActivityCard";
import IncidentReportCard from "../IncidentReportCard";
import JobSummariesContext from "../JobSummariesContext";
import Separator from "../Separator";
import TimeRange from "../TimeRange";

interface JobSummaryProps {
  job: PublicJob;
}

const RightAlignedText = styled(Text)`
  text-align: right;
`;

const JobSummary = ({ job }: JobSummaryProps) => {
  const context = useContext(JobSummariesContext);

  const dailyActivityReports = context.dailyActivityReports.filter(
    (dailyActivityReport) =>
      dailyActivityReport.jobId === job.id && dailyActivityReport.submitted
  );
  const incidentReports = context.incidentReports.filter(
    (incidentReport) =>
      incidentReport.jobId === job.id && incidentReport.submitted
  );

  const [addressLine1, ...addressLine2Array] = context.sites[
    job.siteId
  ].address.split(",");

  return (
    <>
      <AvoidPageBreakInsideContainer>
        <View>
          <Separator />
          <Text style={{ fontSize: 20, fontWeight: "bold" }}>
            {context.sites[job.siteId].name}
          </Text>
          <Separator size="small" />
          <Grid container>
            <Grid item xs={6}>
              <View>
                <Text appearance="hint">Address</Text>
                <Text>{addressLine1}</Text>
                <Text>{addressLine2Array.join(",").trim()}</Text>
              </View>
            </Grid>
            <Grid item xs={6}>
              <View>
                <RightAlignedText appearance="hint">Date</RightAlignedText>
                <TimeRange
                  endDateTime={job.endDateTime}
                  startDateTime={job.startDateTime}
                />
              </View>
            </Grid>
          </Grid>
        </View>
      </AvoidPageBreakInsideContainer>
      <EmployeeActivityCard job={job} />
      {job.taskCompletionRecords.length > 0 && (
        <>
          <Separator />
          <ChecklistCompletionCard job={job} />
        </>
      )}
      {dailyActivityReports.length > 0 && (
        <>
          <Separator />
          {dailyActivityReports.map((dailyActivityReport, index) => (
            <Fragment key={dailyActivityReport.id}>
              {index > 0 && <Separator />}
              <DailyActivityReportCard
                dailyActivityReport={dailyActivityReport}
              />
            </Fragment>
          ))}
        </>
      )}
      {incidentReports.length > 0 && (
        <>
          <Separator />
          {incidentReports.map((incidentReport, index) => (
            <Fragment key={incidentReport.id}>
              {index > 0 && <Separator />}
              <IncidentReportCard incidentReport={incidentReport} />
            </Fragment>
          ))}
        </>
      )}
      <Separator />
    </>
  );
};

export default JobSummary;
