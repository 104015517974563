const reader = (file: Blob) => {
  return new Promise<string>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result as string);
    fileReader.readAsDataURL(file);
  });
};

const getDataUrl = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return await reader(blob);
};

export default getDataUrl;
