import { Grid } from "@material-ui/core";
import { Divider, Text } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { Fragment } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import {
  NormalizedData,
  PublicJobSummariesResponse,
  PublicMedia,
} from "../../types";
import CompanyLogoImage from "../CompanyLogoImage";
import Container from "../Container";
import JobSummary from "../JobSummary";
import JobSummariesContext from "../JobSummariesContext";
import NoPrintContainer from "../NoPrintContainer";
import Separator from "../Separator";
import { dateTimeFormat } from "../../constants";
import UserFullNameText from "../UserFullNameText";

interface JobSummariesProps {
  publicJobSummariesResponse: PublicJobSummariesResponse;
}

const CompanyLogoImageContainer = styled.View`
  height: 96px;
  width: 96px;
`;

const RightAlignedText = styled(Text)`
  text-align: right;
`;

const CompanyNameText = styled(RightAlignedText)`
  font-size: 24px;
`;

const RightAlignedUserFullNameText = styled(UserFullNameText)`
  text-align: right;
`;

const Title = styled(Text)`
  font-size: 48px;
`;

const JobSummaries = ({ publicJobSummariesResponse }: JobSummariesProps) => {
  const media: NormalizedData<PublicMedia> = publicJobSummariesResponse.media.reduce(
    (accumulator, media) => ({
      ...accumulator,
      [media.id]: media,
    }),
    {}
  );
  const companyLogo = publicJobSummariesResponse.company.logo;
  return (
    <JobSummariesContext.Provider
      value={{
        actionItems: publicJobSummariesResponse.actionItems.reduce(
          (accumulator, actionItem) => ({
            ...accumulator,
            [actionItem.id]: actionItem,
          }),
          {}
        ),
        dailyActivityReports: publicJobSummariesResponse.dailyActivityReports,
        incidentReports: publicJobSummariesResponse.incidentReports,
        jobs: publicJobSummariesResponse.jobs.reduce(
          (accumulator, job) => ({
            ...accumulator,
            [job.id]: job,
          }),
          {}
        ),
        media,
        sites: publicJobSummariesResponse.sites.reduce(
          (accumulator, site) => ({
            ...accumulator,
            [site.id]: site,
          }),
          {}
        ),
        users: publicJobSummariesResponse.users.reduce(
          (accumulator, user) => ({
            ...accumulator,
            [user.id]: user,
          }),
          {}
        ),
      }}
    >
      <Container>
        <Separator size={48} />
        <Grid container>
          <Grid item xs={6}>
            <View>
              <CompanyLogoImageContainer>
                {companyLogo && (
                  <CompanyLogoImage url={media[companyLogo.id].url} />
                )}
              </CompanyLogoImageContainer>
              <Separator />
              <Title>Job summary</Title>
            </View>
          </Grid>
          <Grid item xs={6}>
            <View>
              <CompanyNameText>
                {publicJobSummariesResponse.company.name}
              </CompanyNameText>
              <Separator size={84} />
              <RightAlignedText appearance="hint">Created</RightAlignedText>
              <RightAlignedUserFullNameText
                userId={publicJobSummariesResponse.created.by}
              />
              <RightAlignedText>
                {dayjs(publicJobSummariesResponse.created.at).format(
                  dateTimeFormat
                )}
              </RightAlignedText>
            </View>
          </Grid>
        </Grid>
        {publicJobSummariesResponse.jobs.map((job) => (
          <Fragment key={job.id}>
            <Separator />
            <Divider />
            <JobSummary job={job} key={job.id} />
          </Fragment>
        ))}
        <NoPrintContainer>
          <Separator size={64} />
        </NoPrintContainer>
      </Container>
    </JobSummariesContext.Provider>
  );
};

export default JobSummaries;
