import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 960px;
  @media print {
    width: 21cm;
  }
`;

export default Container;
