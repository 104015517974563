import { API } from "aws-amplify";
import gql from "graphql-tag";

import { publicJobSummariesResponseFragment } from "../../fragments";
import { PublicJobSummariesResponse } from "../../../types";

interface GetJobSummaryPublicInput {
  id: string;
}

const getJobSummaryPublic = async ({ id }: GetJobSummaryPublicInput) => {
  // @ts-ignore
  const { data } = await API.graphql({
    query: gql`
      query getJobSummaryPublic($id: ID!) {
        getJobSummaryPublic(id: $id) {
          ...publicJobSummariesResponseFragment
        }
      }
      ${publicJobSummariesResponseFragment}
    `,
    variables: { id },
  });
  return data.getJobSummaryPublic as PublicJobSummariesResponse;
};

export default getJobSummaryPublic;
