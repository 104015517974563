import gql from "graphql-tag";

const userRecordFragment = gql`
  fragment userRecordFragment on UserRecord {
    at
    by
  }
`;

const actionItemFragment = gql`
  fragment actionItemFragment on ActionItem {
    created {
      ...userRecordFragment
    }
    description
    id
    isDeleted
    syncDateTime
    updated {
      ...userRecordFragment
    }
  }
  ${userRecordFragment}
`;

const coordinatesFragment = gql`
  fragment coordinatesFragment on Coordinates {
    lat
    lng
  }
`;

const userLocationRecordFragment = gql`
  fragment userLocationRecordFragment on UserLocationRecord {
    at
    by
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

export const mediaFragment = gql`
  fragment mediaFragment on Media {
    id
    description
    createdAt
    type
  }
`;

const actionItemCompletionRecordFragment = gql`
  fragment actionItemCompletionRecordFragment on ActionItemCompletionRecord {
    actionItemId
    record {
      ...userLocationRecordFragment
    }
    media {
      ...mediaFragment
    }
  }
  ${mediaFragment}
  ${userLocationRecordFragment}
`;

const assigneeFragment = gql`
  fragment assigneeFragment on Assignee {
    id
    type
  }
`;

const locationRecordFragment = gql`
  fragment locationRecordFragment on LocationRecord {
    at
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
`;

const dailyActivityReportFragment = gql`
  fragment dailyActivityReportFragment on DailyActivityReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    started {
      ...locationRecordFragment
    }
    completed {
      ...locationRecordFragment
    }
    summary
    media {
      ...mediaFragment
    }
    syncDateTime
  }
  ${locationRecordFragment}
  ${mediaFragment}
  ${userRecordFragment}
`;

const finishedStatusRecordFragment = gql`
  fragment finishedStatusRecordFragment on FinishedStatusRecord {
    status
    record {
      ...userLocationRecordFragment
    }
  }
  ${userLocationRecordFragment}
`;

const incidentReportFragment = gql`
  fragment incidentReportFragment on IncidentReport {
    id
    jobId
    submitted {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    incidentReportStartDateTime: startDateTime
    incidentReportEndDateTime: endDateTime
    summary
    policeCase {
      agency
      number
    }
    subjects {
      name
      sex
      ethnicity
      race
      phoneNumber
      address
      license {
        number
        region
      }
      description
      type
      height
      weight
      dateOfBirth
      age
      hairColor
      eyeColor
    }
    vehicles {
      description
      make
      model
      year
      color
      license {
        number
        region
      }
      vin
      registeredOwner
      type
      status
    }
    media {
      createdAt
      description
      type
      id
    }
    syncDateTime
  }
  ${userRecordFragment}
`;

const publicCompanyFragment = gql`
  fragment publicCompanyFragment on PublicCompany {
    name
    id
    logo {
      ...mediaFragment
    }
  }
  ${mediaFragment}
`;

const publicGroupFragment = gql`
  fragment publicGroupFragment on PublicGroup {
    id
    name
  }
`;

export const startJobRecordFragment = gql`
  fragment startJobRecordFragment on StartJobRecord {
    record {
      ...userLocationRecordFragment
    }
  }
  ${userLocationRecordFragment}
`;

export const taskCompletionRecordFragment = gql`
  fragment taskCompletionRecordFragment on TaskCompletionRecord {
    taskId
    record {
      ...userLocationRecordFragment
    }
    media {
      ...mediaFragment
    }
  }
  ${mediaFragment}
  ${userLocationRecordFragment}
`;

const taskFragment = gql`
  fragment taskFragment on Task {
    id
    description
  }
`;

const publicJobFragment = gql`
  fragment publicJobFragment on PublicJob {
    id
    startDateTime
    endDateTime
    siteId
    assignedTo {
      ...assigneeFragment
    }
    updated {
      ...userRecordFragment
    }
    created {
      ...userRecordFragment
    }
    tasks {
      ...taskFragment
    }
    commuteRecords {
      by
      at
      distance {
        text
      }
      duration {
        text
      }
    }
    taskCompletionRecords {
      ...taskCompletionRecordFragment
    }
    startJobRecords {
      ...startJobRecordFragment
    }
    finished {
      ...finishedStatusRecordFragment
    }
    rrule
    immediate
    lastSent {
      ...userRecordFragment
    }
    actionItemCompletionRecords {
      ...actionItemCompletionRecordFragment
    }
  }
  ${actionItemCompletionRecordFragment}
  ${assigneeFragment}
  ${finishedStatusRecordFragment}
  ${startJobRecordFragment}
  ${taskCompletionRecordFragment}
  ${taskFragment}
  ${userRecordFragment}
`;

const publicMediaFragment = gql`
  fragment publicMediaFragment on PublicMedia {
    id
    url
  }
`;

const publicSiteFragment = gql`
  fragment publicSiteFragment on PublicSite {
    id
    created {
      ...userRecordFragment
    }
    updated {
      ...userRecordFragment
    }
    address
    name
    geofence
    coordinates {
      ...coordinatesFragment
    }
  }
  ${coordinatesFragment}
  ${userRecordFragment}
`;

const publicUserFragment = gql`
  fragment publicUserFragment on PublicUser {
    id
    firstName
    lastName
  }
`;

export const publicJobSummariesResponseFragment = gql`
  fragment publicJobSummariesResponseFragment on PublicJobSummariesResponse {
    id
    publicUntil
    created {
      ...userRecordFragment
    }
    jobs {
      ...publicJobFragment
    }
    sites {
      ...publicSiteFragment
    }
    users {
      ...publicUserFragment
    }
    media {
      ...publicMediaFragment
    }
    company {
      ...publicCompanyFragment
    }
    groups {
      ...publicGroupFragment
    }
    dailyActivityReports {
      ...dailyActivityReportFragment
    }
    incidentReports {
      ...incidentReportFragment
    }
    actionItems {
      ...actionItemFragment
    }
  }
  ${actionItemFragment}
  ${dailyActivityReportFragment}
  ${incidentReportFragment}
  ${publicCompanyFragment}
  ${publicGroupFragment}
  ${publicJobFragment}
  ${publicMediaFragment}
  ${publicSiteFragment}
  ${publicUserFragment}
  ${userRecordFragment}
`;
