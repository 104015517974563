import React, { ReactNode } from "react";
import styled from "styled-components";

interface AvoidPageBreakInsideContainerProps {
  children?: ReactNode;
}

const Root = styled.div<AvoidPageBreakInsideContainerProps>`
  break-inside: avoid;
`;

const AvoidPageBreakInsideContainer = ({
  children,
  ...props
}: AvoidPageBreakInsideContainerProps) => <Root {...props}>{children}</Root>;

export default AvoidPageBreakInsideContainer;
