import { Text } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import Separator from "../Separator";

interface GridCellProps {
  description: string;
  title: string;
}

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const GridCell = ({ description, title }: GridCellProps) => {
  return (
    <View>
      <StyledText appearance="hint" category="s2">
        {title}
      </StyledText>
      <Separator size="small" />
      <StyledText category="s2">{description}</StyledText>
    </View>
  );
};

export default GridCell;
