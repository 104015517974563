import { Grid } from "@material-ui/core";
import { Text, useTheme } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { regions, vehicleStatuses, vehicleTypes } from "../../constants";

import { Subject, Vehicle } from "../../types";
import GridCell from "../GridCell";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";

interface VehicleEntryProps {
  index: number;
  vehicle: Vehicle;
}

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const VehicleEntry = ({ index, vehicle }: VehicleEntryProps) => {
  return (
    <>
      <Separator size="medium" />
      <StyledText category="s1">{`Vehicle #${index + 1}`}</StyledText>
      <Separator size="medium" />
      <Grid container spacing={2}>
        {vehicle.description && (
          <Grid item xs={12}>
            <GridCell description={vehicle.description} title="Description" />
          </Grid>
        )}
        {vehicle.type && (
          <Grid item xs={3}>
            <GridCell
              description={vehicleTypes[vehicle.type]?.text}
              title="Type"
            />
          </Grid>
        )}
        {vehicle.status && (
          <Grid item xs={3}>
            <GridCell
              description={vehicleStatuses[vehicle.status]?.text}
              title="Status"
            />
          </Grid>
        )}
        {vehicle.registeredOwner && (
          <Grid item xs={3}>
            <GridCell
              description={vehicle.registeredOwner}
              title="Registered owner"
            />
          </Grid>
        )}
        {vehicle.color && (
          <Grid item xs={3}>
            <GridCell description={vehicle.color} title="Color" />
          </Grid>
        )}
        {vehicle.year && (
          <Grid item xs={3}>
            <GridCell description={vehicle.year.toString()} title="Year" />
          </Grid>
        )}
        {vehicle.make && (
          <Grid item xs={3}>
            <GridCell description={vehicle.make} title="Make" />
          </Grid>
        )}
        {vehicle.model && (
          <Grid item xs={3}>
            <GridCell description={vehicle.model} title="Model" />
          </Grid>
        )}
        {vehicle.vin && (
          <Grid item xs={3}>
            <GridCell description={vehicle.vin} title="VIN" />
          </Grid>
        )}
        {vehicle.license && (
          <Grid item xs={3}>
            <GridCell
              description={`${vehicle.license.number}${
                vehicle.license.number ? "\n" : ""
              }${
                vehicle.license.region && regions[vehicle.license.region]?.text
              }`}
              title="Vehicle license"
            />
          </Grid>
        )}
      </Grid>
      <Separator size="medium" />
    </>
  );
};

export default VehicleEntry;
