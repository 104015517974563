import { Text, TextProps } from "@ui-kitten/components";
import React, { useContext } from "react";
import JobSummariesContext from "../JobSummariesContext";

interface UserFullNameTextProps extends TextProps {
  userId: string;
}

const UserFullNameText = ({ userId, ...props }: UserFullNameTextProps) => {
  const { users } = useContext(JobSummariesContext);
  const user = users[userId];
  return <Text {...props}>{`${user.firstName} ${user.lastName}`}</Text>;
};

export default UserFullNameText;
