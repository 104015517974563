import { PublicUser } from "../../types";

const sortUsers = (a: PublicUser, b: PublicUser) => {
  const lastNameComparison = a.lastName.localeCompare(b.lastName);
  const firstNameComparison = a.firstName.localeCompare(b.firstName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  return firstNameComparison;
};

export default sortUsers;
