const googleMaps = __DEV__ ? "" : "AIzaSyAsXBqN3KbIOSZzuDl0cupIkQW1Pc06U00";

const dev = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:3ada6ff7-7fea-498d-b0f0-1e59b03d601c",
      region: "us-west-2",
      userPoolId: "us-west-2_obFOgoo4S",
      userPoolWebClientId: "7ke0kh9flaf6u905gkshnb2n16",
    },
    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint:
      "https://n73hwv67kff3hkisyxdvo2l6mm.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
  },
  googleMaps,
  sentry: {
    environment: "development",
  },
};

const staging = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:f4a2d642-b8a1-4279-86fe-26828fd26a91",
      region: "us-west-2",
      userPoolId: "us-west-2_JaCzk8xmg",
      userPoolWebClientId: "3pbr5bj4m3gs28nkgk51i0lps3",
    },
    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint:
      "https://aoutxyytjnfp3cqzvz6rrfoskq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    Storage: {
      AWSS3: {
        bucket: "talarium-media-us-west-2-369582980234",
        region: "us-west-2",
        level: "public",
      },
    },
  },
  googleMaps,
  sentry: {
    environment: "staging",
  },
};

const prod = {
  aws: {
    Auth: {
      identityPoolId: "us-west-2:14c3c9ae-aab7-44e5-91cf-f18d873644b4",
      region: "us-west-2",
      userPoolId: "us-west-2_M1WhW2Bz3",
      userPoolWebClientId: "3mklp3n0lees3f7a21sistf27a",
    },
    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint:
      "https://phmrdcghujh5le3ka6w6efu3sq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
  },
  googleMaps,
  sentry: {
    environment: "production",
  },
};

let config = dev;
if (!__DEV__) {
  config = prod;
}

export default config;
