import { Fab, makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { Button, Input, Spinner, Text, useTheme } from "@ui-kitten/components";
import * as Linking from "expo-linking";
import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { toast } from "react-toastify";
import styled from "styled-components/native";

import getJobSummaryPublic from "../../api/functions/getJobSummaryPublic";
import JobSummaries from "../../components/JobSummaries";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import Separator from "../../components/Separator";
import { PublicJobSummariesResponse } from "../../types";

const styles = StyleSheet.create({
  boldText: {
    fontFamily: "bold",
  },
  button: {
    alignSelf: "flex-start",
  },
  contentContainer: {
    paddingEnd: 16,
    paddingStart: 16,
  },
  talariumLogo: {
    height: 48,
    resizeMode: "contain",
    width: "100%",
  },
  spinnerContainer: {
    alignItems: "center",
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    color: (props) => props.theme["color-primary-default"],
  },
  fab: {
    bottom: theme.spacing(2),
    position: "fixed",
    right: theme.spacing(2),
    "@media print": {
      display: "none",
    },
  },
}));

const Root = styled.View`
  flex: 1;
`;

const Home = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { path: jobId } = Linking.parse(window.location.href);

  const [searchJobId, setSearchJobId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [
    publicJobSummariesResponse,
    setPublicJobSummariesResponse,
  ] = useState<PublicJobSummariesResponse>();

  const runGetJobSummaryPublic = useCallback(async () => {
    if (jobId) {
      setLoading(true);
      try {
        const publicJobSummariesResponse = await getJobSummaryPublic({
          id: jobId,
        });
        setPublicJobSummariesResponse(publicJobSummariesResponse);
      } catch ({ errors }) {
        console.error(errors);
        const firstError = errors[0];
        toast(firstError.message, { type: "error" });
      }
      setLoading(false);
    }
  }, [jobId]);

  useEffect(() => {
    setSearchJobId("");
  }, [jobId]);
  useEffect(() => {
    runGetJobSummaryPublic();
  }, [runGetJobSummaryPublic]);

  const renderContent = () => {
    if (loading) {
      return (
        <View style={styles.spinnerContainer}>
          <Separator size={48} />
          <Spinner />
        </View>
      );
    }
    if (publicJobSummariesResponse) {
      return (
        <JobSummaries publicJobSummariesResponse={publicJobSummariesResponse} />
      );
    }
    if (jobId) {
      return (
        <ResponsiveContainer style={styles.contentContainer}>
          <Separator />
          <Text category="h6">Error</Text>
          <Separator size="small" />
          <Text>
            {"There was a problem while retrieving the job summary. (ID: "}
            <Text category="s1">{jobId}</Text>
            <Text>)</Text>
          </Text>
          <Separator size="medium" />
          <Button
            onPress={runGetJobSummaryPublic}
            size="small"
            style={styles.button}
          >
            Retry
          </Button>
          <Separator />
          <Text>or</Text>
          <Separator />
          <Input
            label="Enter another job summary ID"
            onChangeText={setSearchJobId}
            placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
            value={searchJobId}
          />
          <Separator size="small" />
          <Button
            onPress={() => Linking.openURL(Linking.createURL(searchJobId))}
            size="small"
            style={styles.button}
          >
            Search
          </Button>
          <Separator />
        </ResponsiveContainer>
      );
    }
    return (
      <ResponsiveContainer style={styles.contentContainer}>
        <Separator />
        <Text category="h6">Welcome</Text>
        <Separator size="small" />
        <Input
          label="Enter a job summary ID"
          onChangeText={setSearchJobId}
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          value={searchJobId}
        />
        <Separator size="small" />
        <Button
          onPress={() => Linking.openURL(Linking.createURL(searchJobId))}
          size="small"
          style={styles.button}
        >
          Search
        </Button>
        <Separator />
      </ResponsiveContainer>
    );
  };

  return (
    <Root>
      {renderContent()}
      {publicJobSummariesResponse && (
        <Fab className={classes.fab} onClick={window.print} variant="extended">
          <GetApp />
          Download
        </Fab>
      )}
    </Root>
  );
};

export default Home;
