import { ThemeType, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components";

import AvoidPageBreakInsideContainer from "../../AvoidPageBreakInsideContainer";

interface PdfCenterRowProps {
  children?: React.ReactNode;
}

const StyledAvoidPageBreakInsideContainer = styled(
  AvoidPageBreakInsideContainer
)<{ theme: ThemeType }>`
  border-color: ${({ theme }) => theme["border-basic-color-3"]};
  border-style: none solid none;
  border-width: 0 1px;
  overflow: hidden;
`;

const PdfCenterRow = ({ children }: PdfCenterRowProps) => {
  const theme = useTheme();
  return (
    <StyledAvoidPageBreakInsideContainer theme={theme}>
      {children}
    </StyledAvoidPageBreakInsideContainer>
  );
};

export default PdfCenterRow;
