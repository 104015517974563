import { ThemeType, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components";

import AvoidPageBreakInsideContainer from "../../AvoidPageBreakInsideContainer";

interface PdfTopRowProps {
  children?: React.ReactNode;
}

const StyledAvoidPageBreakInsideContainer = styled(
  AvoidPageBreakInsideContainer
)<{ theme: ThemeType }>`
  border-color: ${({ theme }) => theme["border-basic-color-3"]};
  border-radius: 8px 8px 0 0;
  border-style: solid solid none;
  border-width: 1px 1px 0 1px;
  overflow: hidden;
`;

const PdfTopRow = ({ children }: PdfTopRowProps) => {
  const theme = useTheme();
  return (
    <StyledAvoidPageBreakInsideContainer theme={theme}>
      {children}
    </StyledAvoidPageBreakInsideContainer>
  );
};

export default PdfTopRow;
