import React, { ReactNode } from "react";
import styled from "styled-components";

interface NoPrintContainerProps {
  children?: ReactNode;
}

const Root = styled.div`
  @media print {
    display: none;
  }
`;

const NoPrintContainer = ({ children }: NoPrintContainerProps) => (
  <Root>{children}</Root>
);

export default NoPrintContainer;
