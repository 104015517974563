import { Grid } from "@material-ui/core";
import { Divider, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { dateTimeFormat } from "../../constants";
import { IncidentReport } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import GridCell from "../GridCell";
import HeaderContainer from "../HeaderContainer";
import JobSummariesContext from "../JobSummariesContext";
import PdfRow from "../PdfRow";
import ReportMedia from "../ReportMedia";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";
import SubjectEntry from "../SubjectEntry";
import VehicleEntry from "../VehicleEntry";

interface DailyActivityReportCardProps {
  incidentReport: IncidentReport;
}

const HeaderTextLineContainer = styled.View`
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
`;

const Row = styled.View`
  flex-direction: row;
`;

const SummaryContainer = styled.View`
  flex: 3;
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const ItalicsText = styled(StyledText)`
  font-style: italic;
`;

const SubheaderTextLineContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

const IncidentReportCard = ({
  incidentReport,
}: DailyActivityReportCardProps) => {
  const theme = useTheme();

  const context = useContext(JobSummariesContext);

  const reportContainsPoliceCase = Boolean(incidentReport.policeCase);
  const reportContainsSubjects = incidentReport.subjects.length > 0;
  const reportContainsVehicles = incidentReport.vehicles.length > 0;
  const reportContainsMedia = incidentReport.media.length > 0;

  return (
    <>
      <PdfRow
        position={
          reportContainsPoliceCase ||
          reportContainsSubjects ||
          reportContainsVehicles ||
          reportContainsMedia
            ? "top"
            : undefined
        }
      >
        <View>
          <HeaderContainer theme={theme}>
            <Separator size="small" />
            <HeaderTextLineContainer>
              <StyledText category="h6" status="control">
                Incident Report
              </StyledText>
              <StyledText status="control">
                <Text category="s1" status="control">
                  Started at:
                </Text>
                {` ${dayjs(incidentReport.startDateTime).format(
                  dateTimeFormat
                )}`}
              </StyledText>
            </HeaderTextLineContainer>
            <HeaderTextLineContainer>
              <StyledText status="control">
                <Text category="s1" status="control">
                  Submitted by:
                </Text>
                {` ${getUserFullName(
                  context.users[incidentReport.submitted?.by]
                )}`}
              </StyledText>
              <StyledText status="control">
                <Text category="s1" status="control">
                  Ended at:
                </Text>
                {` ${dayjs(incidentReport.endDateTime).format(dateTimeFormat)}`}
              </StyledText>
            </HeaderTextLineContainer>
            <Separator size="small" />
          </HeaderContainer>
          <SubheaderContainer theme={theme}>
            <Separator size="small" />
            <SubheaderTextLineContainer>
              <SummaryContainer>
                <StyledText category="s1" status="primary">
                  Summary
                </StyledText>
              </SummaryContainer>
            </SubheaderTextLineContainer>
            <Separator size="small" />
          </SubheaderContainer>
          <Separator size="medium" />
          {incidentReport.summary ? (
            <StyledText category="s2">{incidentReport.summary}</StyledText>
          ) : (
            <ItalicsText appearance="hint">No summary available</ItalicsText>
          )}
          <Separator size="medium" />
        </View>
      </PdfRow>
      {reportContainsPoliceCase && (
        <PdfRow
          position={
            reportContainsSubjects ||
            reportContainsVehicles ||
            reportContainsMedia
              ? "center"
              : "bottom"
          }
        >
          <View>
            <SubheaderContainer theme={theme}>
              <Separator size="small" />
              <StyledText category="s1" status="primary">
                Police case
              </StyledText>
              <Separator size="small" />
            </SubheaderContainer>
            <Separator size="medium" />
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <GridCell
                  description={incidentReport.policeCase.number}
                  title="Case number"
                />
              </Grid>
              <Grid item xs={3}>
                <GridCell
                  description={incidentReport.policeCase.agency}
                  title="Agency"
                />
              </Grid>
            </Grid>
            <Separator size="medium" />
          </View>
        </PdfRow>
      )}
      {reportContainsSubjects && (
        <>
          {incidentReport.subjects.map((subject, index, array) => (
            <PdfRow
              position={
                reportContainsVehicles ||
                reportContainsMedia ||
                index !== array.length - 1
                  ? "center"
                  : "bottom"
              }
            >
              <View>
                {index === 0 && (
                  <SubheaderContainer theme={theme}>
                    <Separator size="small" />
                    <StyledText category="s1" status="primary">
                      Subjects
                    </StyledText>
                    <Separator size="small" />
                  </SubheaderContainer>
                )}
                {index > 0 && <Divider />}
                <SubjectEntry index={index} subject={subject} />
              </View>
            </PdfRow>
          ))}
        </>
      )}
      {reportContainsVehicles && (
        <>
          {incidentReport.vehicles.map((vehicle, index, array) => (
            <PdfRow
              position={
                reportContainsMedia || index !== array.length - 1
                  ? "center"
                  : "bottom"
              }
            >
              <View>
                {index === 0 && (
                  <SubheaderContainer theme={theme}>
                    <Separator size="small" />
                    <StyledText category="s1" status="primary">
                      Vehicles
                    </StyledText>
                    <Separator size="small" />
                  </SubheaderContainer>
                )}
                {index > 0 && <Divider />}
                <VehicleEntry index={index} vehicle={vehicle} />
              </View>
            </PdfRow>
          ))}
        </>
      )}
      {reportContainsMedia && <ReportMedia media={incidentReport.media} />}
    </>
  );
};

export default IncidentReportCard;
