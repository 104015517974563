import { ThemeType } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components/native";

interface HeaderContainerProps {
  theme: ThemeType;
}

const HeaderContainer = styled.View<HeaderContainerProps>`
  background-color: ${(props) => props.theme["color-primary-default"]};
`;

export default HeaderContainer;
