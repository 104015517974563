import { createContext } from "react";

import {
  ActionItem,
  DailyActivityReport,
  IncidentReport,
  NormalizedData,
  PublicJob,
  PublicMedia,
  PublicSite,
  PublicUser,
} from "../../types";

interface JobSummariesContextInterface {
  actionItems: NormalizedData<ActionItem>;
  dailyActivityReports: Array<DailyActivityReport>;
  incidentReports: Array<IncidentReport>;
  jobs: NormalizedData<PublicJob>;
  media: NormalizedData<PublicMedia>;
  sites: NormalizedData<PublicSite>;
  users: NormalizedData<PublicUser>;
}

const JobSummariesContext = createContext<JobSummariesContextInterface>(
  undefined!
);

export default JobSummariesContext;
