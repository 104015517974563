import { Divider, Text, useTheme } from "@ui-kitten/components";
import React, { Fragment, useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import {
  ActionItemCompletionRecord,
  PublicJob,
  TaskCompletionRecord,
} from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import sortUsers from "../../utils/sortUsers";
import ChecklistItemCompletionEntry from "../ChecklistItemCompletionEntry";
import HeaderContainer from "../HeaderContainer";
import JobSummariesContext from "../JobSummariesContext";
import PdfRow from "../PdfRow";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";

interface ChecklistCompletionCardProps {
  job: PublicJob;
}

const StyledText = styled(Text)`
  padding-left: 16px;
  padding-right: 16px;
`;

const ChecklistCompletionCard = ({ job }: ChecklistCompletionCardProps) => {
  const theme = useTheme();

  const context = useContext(JobSummariesContext);

  const checklistItemCompletionRecordsByUser: Record<
    string,
    Array<ActionItemCompletionRecord | TaskCompletionRecord>
  > = {};
  job.taskCompletionRecords.forEach((taskCompletionRecord) => {
    if (checklistItemCompletionRecordsByUser[taskCompletionRecord.record.by]) {
      checklistItemCompletionRecordsByUser[taskCompletionRecord.record.by].push(
        taskCompletionRecord
      );
    } else {
      checklistItemCompletionRecordsByUser[taskCompletionRecord.record.by] = [
        taskCompletionRecord,
      ];
    }
  });
  job.actionItemCompletionRecords?.forEach((actionItemCompletionRecord) => {
    if (
      checklistItemCompletionRecordsByUser[actionItemCompletionRecord.record.by]
    ) {
      checklistItemCompletionRecordsByUser[
        actionItemCompletionRecord.record.by
      ].push(actionItemCompletionRecord);
    } else {
      checklistItemCompletionRecordsByUser[
        actionItemCompletionRecord.record.by
      ] = [actionItemCompletionRecord];
    }
  });
  const userIds = Object.keys(
    checklistItemCompletionRecordsByUser
  ).sort((a, b) => sortUsers(context.users[a], context.users[b]));

  const getPdfRowPosition = ({
    checklistItemCompletionRecordIndex,
    checklistItemCompletionRecordsArray,
    userIdIndex,
    userIdsArray,
  }: {
    checklistItemCompletionRecordIndex: number;
    checklistItemCompletionRecordsArray: Array<any>;
    userIdIndex: number;
    userIdsArray: Array<any>;
  }) => {
    if (
      userIdsArray.length === 1 &&
      checklistItemCompletionRecordsArray.length === 1
    ) {
      return undefined;
    }
    if (userIdIndex === 0 && checklistItemCompletionRecordIndex === 0) {
      return "top";
    }
    if (
      userIdIndex === userIdsArray.length - 1 &&
      checklistItemCompletionRecordIndex ===
        checklistItemCompletionRecordsArray.length - 1
    ) {
      return "bottom";
    }
    return "center";
  };

  return (
    <>
      {userIds.map((userId, userIdIndex, userIdsArray) => {
        return (
          <Fragment key={userId}>
            {checklistItemCompletionRecordsByUser[userId]
              .sort((a, b) => a.record.at.localeCompare(b.record.at))
              .map(
                (
                  checklistItemCompletionRecord,
                  checklistItemCompletionRecordIndex,
                  checklistItemCompletionRecordsArray
                ) => (
                  <PdfRow
                    key={
                      "taskId" in checklistItemCompletionRecord
                        ? checklistItemCompletionRecord.taskId
                        : checklistItemCompletionRecord.actionItemId
                    }
                    position={getPdfRowPosition({
                      checklistItemCompletionRecordIndex,
                      checklistItemCompletionRecordsArray,
                      userIdIndex,
                      userIdsArray,
                    })}
                  >
                    <View>
                      {userIdIndex === 0 &&
                        checklistItemCompletionRecordIndex === 0 && (
                          <HeaderContainer theme={theme}>
                            <Separator size="small" />
                            <StyledText category="h6" status="control">
                              Completed tasks
                            </StyledText>
                            <Separator size="small" />
                          </HeaderContainer>
                        )}
                      {checklistItemCompletionRecordIndex === 0 && (
                        <SubheaderContainer theme={theme}>
                          <Separator size="small" />
                          <StyledText category="s1" status="primary">
                            {getUserFullName(context.users[userId])}
                          </StyledText>
                          <Separator size="small" />
                        </SubheaderContainer>
                      )}
                      {checklistItemCompletionRecordIndex > 0 && <Divider />}
                      <ChecklistItemCompletionEntry
                        checklistItemCompletionRecord={
                          checklistItemCompletionRecord
                        }
                        job={job}
                      />
                    </View>
                  </PdfRow>
                )
              )}
          </Fragment>
        );
      })}
    </>
  );
};

export default ChecklistCompletionCard;
