import { ThemeType, useTheme } from "@ui-kitten/components";
import React from "react";
import styled from "styled-components";

import AvoidPageBreakInsideContainer from "../../AvoidPageBreakInsideContainer";

interface PdfBottomRowProps {
  children?: React.ReactNode;
}

const StyledAvoidPageBreakInsideContainer = styled(
  AvoidPageBreakInsideContainer
)<{ theme: ThemeType }>`
  border-color: ${({ theme }) => theme["border-basic-color-3"]};
  border-radius: 0 0 8px 8px;
  border-style: none solid solid;
  border-width: 0 1px 1px 1px;
  overflow: hidden;
`;

const PdfBottomRow = ({ children }: PdfBottomRowProps) => {
  const theme = useTheme();
  return (
    <StyledAvoidPageBreakInsideContainer theme={theme}>
      {children}
    </StyledAvoidPageBreakInsideContainer>
  );
};

export default PdfBottomRow;
