import { FinishedStatuses } from "../types";

export const dateFormat = "MMM D, YYYY";
export const timeFormat = "h:mm A ddd";
export const dateTimeFormat = `${timeFormat}, ${dateFormat}`;

export const mapDefaultZoom = 15;

export const ethnicities = {
  HISPANIC: {
    text: "Hispanic",
    value: "HISPANIC",
  },
  NON_HISPANIC: {
    text: "Non-hispanic",
    value: "NON_HISPANIC",
  },
  UNKNOWN: {
    text: "Unknown",
    value: "UNKNOWN",
  },
};

export const eyeColors = {
  BROWN: {
    text: "Brown",
    value: "BROWN",
  },
  BLUE: {
    text: "Blue",
    value: "BLUE",
  },
  GREEN: {
    text: "Green",
    value: "GREEN",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const finishedJobStatuses: {
  [finishedJobStatus in FinishedStatuses]: {
    value: FinishedStatuses;
    text: string;
  };
} = {
  completed: {
    text: "Completed",
    value: "completed",
  },
  deleted: {
    text: "Deleted",
    value: "deleted",
  },
  dismissed: {
    text: "Dismissed",
    value: "dismissed",
  },
};

export const hairColors = {
  BLONDE: {
    text: "Blonde",
    value: "BLONDE",
  },
  BROWN: {
    text: "Brown",
    value: "BROWN",
  },
  BLACK: {
    text: "Black",
    value: "BLACK",
  },
  RED: {
    text: "Red",
    value: "RED",
  },
  BALD: {
    text: "Bald",
    value: "BALD",
  },
  GREY: {
    text: "Grey",
    value: "GREY",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const races = {
  WHITE: {
    text: "White",
    value: "WHITE",
  },
  BLACK: {
    text: "Black",
    value: "BLACK",
  },
  ASIAN: {
    text: "Asian",
    value: "ASIAN",
  },
  NATIVE_AMERICAN: {
    text: "Native American",
    value: "NATIVE_AMERICAN",
  },
  UNKNOWN: {
    text: "Unknown",
    value: "UNKNOWN",
  },
};

export const regions: {
  [region: string]: { text: string; value: string } | undefined;
} = {
  AL: {
    text: "Alabama",
    value: "AL",
  },
  AK: {
    text: "Alaska",
    value: "AK",
  },
  AZ: {
    text: "Arizona",
    value: "AZ",
  },
  AR: {
    text: "Arkansas",
    value: "AR",
  },
  CA: {
    text: "California",
    value: "CA",
  },
  CO: {
    text: "Colorado",
    value: "CO",
  },
  CT: {
    text: "Connecticut",
    value: "CT",
  },
  DE: {
    text: "Delaware",
    value: "DE",
  },
  DC: {
    text: "District of Columbia",
    value: "DC",
  },
  FL: {
    text: "Florida",
    value: "FL",
  },
  GA: {
    text: "Georgia",
    value: "GA",
  },
  HI: {
    text: "Hawaii",
    value: "HI",
  },
  ID: {
    text: "Idaho",
    value: "ID",
  },
  IL: {
    text: "Illinois",
    value: "IL",
  },
  IN: {
    text: "Indiana",
    value: "IN",
  },
  IA: {
    text: "Iowa",
    value: "IA",
  },
  KS: {
    text: "Kansas",
    value: "KS",
  },
  KY: {
    text: "Kentucky",
    value: "KY",
  },
  LA: {
    text: "Louisana",
    value: "LA",
  },
  ME: {
    text: "Maine",
    value: "ME",
  },
  MD: {
    text: "Maryland",
    value: "MD",
  },
  MA: {
    text: "Massachusetts",
    value: "MA",
  },
  MI: {
    text: "Michigan",
    value: "MI",
  },
  MN: {
    text: "Minnesota",
    value: "MN",
  },
  MS: {
    text: "Mississippi",
    value: "MS",
  },
  MO: {
    text: "Missouri",
    value: "MO",
  },
  MT: {
    text: "Montana",
    value: "MT",
  },
  NE: {
    text: "Nebraska",
    value: "NE",
  },
  NV: {
    text: "Nevada",
    value: "NV",
  },
  NH: {
    text: "New Hampshire",
    value: "NH",
  },
  NJ: {
    text: "New Jersey",
    value: "NJ",
  },
  NM: {
    text: "New Mexico",
    value: "NM",
  },
  NY: {
    text: "New York",
    value: "NY",
  },
  NC: {
    text: "North Carolina",
    value: "NC",
  },
  ND: {
    text: "North Dakota",
    value: "ND",
  },
  OH: {
    text: "Ohio",
    value: "OH",
  },
  OK: {
    text: "Oklahoma",
    value: "OK",
  },
  OR: {
    text: "Oregon",
    value: "OR",
  },
  PA: {
    text: "Pennsylvania",
    value: "PA",
  },
  RI: {
    text: "Rhode Island",
    value: "RI",
  },
  SC: {
    text: "South Carolina",
    value: "SC",
  },
  SD: {
    text: "South Dakota",
    value: "SD",
  },
  TN: {
    text: "Tennessee",
    value: "TN",
  },
  TX: {
    text: "Texas",
    value: "TX",
  },
  UT: {
    text: "Utah",
    value: "UT",
  },
  VT: {
    text: "Vermont",
    value: "VT",
  },
  VA: {
    text: "Virginia",
    value: "VA",
  },
  WA: {
    text: "Washington",
    value: "WA",
  },
  WV: {
    text: "West Virginia",
    value: "WV",
  },
  WI: {
    text: "Wisconsin",
    value: "WI",
  },
  WY: {
    text: "Wyoming",
    value: "WY",
  },
};

export const sexes = {
  MALE: {
    text: "Male",
    value: "MALE",
  },
  FEMALE: {
    text: "Female",
    value: "FEMALE",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const subjectTypes = {
  VICTIM: {
    text: "Victim",
    value: "VICTIM",
  },
  INTERVIEWED: {
    text: "Interviewed",
    value: "INTERVIEWED",
  },
  REPORTING_PERSON: {
    text: "Reporting person",
    value: "REPORTING_PERSON",
  },
  PARENT_GUARDIAN: {
    text: "Parent/Guardian",
    value: "PARENT_GUARDIAN",
  },
  WITNESS: {
    text: "Witness",
    value: "WITNESS",
  },
  DECEASED: {
    text: "Deceased",
    value: "DECEASED",
  },
  MISSING_RUNAWAY: {
    text: "Missing/Runaway",
    value: "MISSING_RUNAWAY",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const vehicleStatuses = {
  STOLEN: {
    text: "Stolen",
    value: "STOLEN",
  },
  BURNED: {
    text: "Burned",
    value: "BURNED",
  },
  REC: {
    text: "REC",
    value: "REC",
  },
  ABANDONED: {
    text: "Abandoned",
    value: "ABANDONED",
  },
  SEIZED: {
    text: "Seized",
    value: "SEIZED",
  },
  DAMAGED_VANDALIZED: {
    text: "Damaged/Vandalized",
    value: "DAMAGED_VANDALIZED",
  },
  SUSPECT_VEHICLE: {
    text: "Suspect vehicle",
    value: "SUSPECT_VEHICLE",
  },
  VICTIM_VEHICLE: {
    text: "Victim vehicle",
    value: "VICTIM_VEHICLE",
  },
  REPOSSESSED: {
    text: "Repossessed",
    value: "REPOSSESSED",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};

export const vehicleTypes = {
  AIRPLANE: {
    text: "Airplane",
    value: "AIRPLANE",
  },
  AUTOMOBILE: {
    text: "Automobile",
    value: "AUTOMOBILE",
  },
  BUS: {
    text: "Bus",
    value: "BUS",
  },
  MOTOR_HOME: {
    text: "Motor home",
    value: "MOTOR_HOME",
  },
  TRUCK: {
    text: "Truck",
    value: "TRUCK",
  },
  OTHER: {
    text: "Other",
    value: "OTHER",
  },
};
