import React from "react";
import styled from "styled-components/native";

const ResponsiveContainer = styled.View`
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  width: 100%;
`;

export default ResponsiveContainer;
