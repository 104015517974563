import { Grid } from "@material-ui/core";
import { Divider, Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { dateTimeFormat } from "../../constants";
import { Media } from "../../types";
import AspectRatioView from "../AspectRatioView";
import JobSummariesContext from "../JobSummariesContext";
import PdfRow from "../PdfRow";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";

interface ReportMediaProps {
  media: Array<Media>;
}

const MediaContainer = styled.View`
  height: 100%;
  padding: 8px;
`;

const TextContainer = styled.View`
  padding-left: 16px;
  padding-right: 16px;
`;

const ItalicsText = styled(Text)`
  font-style: italic;
`;

const StyledImage = styled.Image`
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const ReportMedia = ({ media }: ReportMediaProps) => {
  const theme = useTheme();

  const context = useContext(JobSummariesContext);

  return (
    <>
      {media.map((element, index, array) => (
        <PdfRow
          key={element.id}
          position={index === array.length - 1 ? "bottom" : "center"}
        >
          <View>
            {index === 0 && (
              <SubheaderContainer theme={theme}>
                <Separator size="small" />
                <TextContainer>
                  <Text category="s1" status="primary">
                    Photo documents
                  </Text>
                </TextContainer>
                <Separator size="small" />
              </SubheaderContainer>
            )}
            {index > 0 && <Divider />}
            <Grid container>
              <Grid item xs={9}>
                <View>
                  <Separator size="medium" />
                  <TextContainer>
                    <Text appearance="hint" category="s2">
                      {dayjs(element.createdAt).format(dateTimeFormat)}
                    </Text>
                    <Separator size="small" />
                    {element.description ? (
                      <Text category="s2">{element.description}</Text>
                    ) : (
                      <ItalicsText appearance="hint">
                        No description available
                      </ItalicsText>
                    )}
                  </TextContainer>
                  <Separator size="medium" />
                </View>
              </Grid>
              <Grid item xs={3}>
                <View>
                  <MediaContainer>
                    <AspectRatioView height={9} width={16}>
                      <StyledImage
                        resizeMode="cover"
                        source={{ uri: context.media[element.id].url }}
                      />
                    </AspectRatioView>
                  </MediaContainer>
                </View>
              </Grid>
            </Grid>
          </View>
        </PdfRow>
      ))}
    </>
  );
};

export default ReportMedia;
