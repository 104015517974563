import GoogleMapReact, { Props } from "google-map-react";
import React, { PropsWithChildren } from "react";

import config from "../../config";

type DefaultGoogleMapReactProps = PropsWithChildren<
  Exclude<Props, "bootstrapURLKeys">
>;

const DefaultGoogleMapReact = (props: DefaultGoogleMapReactProps) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: config.googleMaps }}
    options={() => ({
      clickableIcons: false,
      draggable: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
      mapTypeControl: false,
      panControl: false,
      rotateControl: false,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: false,
      zoomControl: false,
    })}
    {...props}
  />
);

export default DefaultGoogleMapReact;
