import * as eva from "@eva-design/eva";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import Amplify from "aws-amplify";
import Constants from "expo-constants";
import React from "react";
import * as Sentry from "sentry-expo";
import styled from "styled-components";

import Toast from "./src/components/Toast";
import config from "./src/config";
import Home from "./src/screens/Home";
import theme from "./src/theme";

Amplify.configure(config.aws);
Sentry.init({
  dsn:
    "https://73e8deb319d14e41b62a5964dd168a0b@o364106.ingest.sentry.io/5708377",
  enableAutoSessionTracking: true,
  enableInExpoDevelopment: true,
  environment: config.sentry.environment,
  release: `web@v${Constants.manifest.version}`,
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  width: 100%;
  @media {
    * {
      -webkit-print-color-adjust: exact;
    }
  }
`;

const App = () => {
  return (
    <Root>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
        <Home />
        <Toast />
      </ApplicationProvider>
    </Root>
  );
};

export default App;
