import { Grid } from "@material-ui/core";
import { Divider, Text, ThemeType, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { dateTimeFormat } from "../../constants";
import { PublicJob } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import sortUsers from "../../utils/sortUsers";
import HeaderContainer from "../HeaderContainer";
import JobSummariesContext from "../JobSummariesContext";
import PdfRow from "../PdfRow";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";

interface EmployeeActivityCardProps {
  job: PublicJob;
}

interface ActivityEntry {
  dateTime: string;
  description: string;
  title: string;
}

const ConnectorContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

const Dot = styled.View<{ theme: ThemeType }>`
  border-color: ${(props) => props.theme["text-hint-color"]}
  border-radius: 12px;
  border-width: 2px;
  height: 12px;
  width: 12px;
`;

const StyledDivider = styled(Divider)`
  flex: 1;
`;

const TextContainer = styled.View`
  padding-left: 16px;
  padding-right: 16px;
`;

const EmployeeActivityCard = ({ job }: EmployeeActivityCardProps) => {
  const theme = useTheme();

  const { users } = useContext(JobSummariesContext);

  const activityByUser: Record<string, Array<ActivityEntry>> = {};
  job.commuteRecords?.forEach((commuteRecord) => {
    activityByUser[commuteRecord.by] = [
      {
        dateTime: commuteRecord.at,
        description: `${dayjs(commuteRecord.at).format(dateTimeFormat)}\n${
          commuteRecord.duration.text
        } \u2022 ${commuteRecord.distance.text}`,
        title: "Travel started",
      },
    ];
  });
  job.startJobRecords.forEach((startJobRecord) => {
    const activityEntry = {
      dateTime: startJobRecord.record.at,
      description: dayjs(startJobRecord.record.at).format(dateTimeFormat),
      title: "Job started",
    };
    if (activityByUser[startJobRecord.record.by]) {
      activityByUser[startJobRecord.record.by].push(activityEntry);
    } else {
      activityByUser[startJobRecord.record.by] = [activityEntry];
    }
  });
  if (job.finished) {
    const activityEntry = {
      dateTime: job.finished.record.at,
      description: dayjs(job.finished.record.at).format(dateTimeFormat),
      title: `Job ${job.finished.status}`,
    };
    if (activityByUser[job.finished.record.by]) {
      activityByUser[job.finished.record.by].push(activityEntry);
    } else {
      activityByUser[job.finished.record.by] = [activityEntry];
    }
  }
  const userIds = Object.keys(activityByUser).sort((a, b) =>
    sortUsers(users[a], users[b])
  );

  const getPdfRowPosition = ({
    array,
    index,
  }: {
    array: Array<any>;
    index: number;
  }) => {
    if (array.length === 1) {
      return undefined;
    }
    if (index === 0) {
      return "top";
    }
    if (index === array.length - 1) {
      return "bottom";
    }
    return "center";
  };

  return (
    <>
      {userIds.length > 0 && (
        <>
          <Separator />
          {userIds.map((userId, index, array) => (
            <PdfRow key={userId} position={getPdfRowPosition({ array, index })}>
              <View>
                {index === 0 && (
                  <HeaderContainer theme={theme}>
                    <Separator size="small" />
                    <TextContainer>
                      <Text category="h6" status="control">
                        Employee Activity
                      </Text>
                    </TextContainer>
                    <Separator size="small" />
                  </HeaderContainer>
                )}
                <SubheaderContainer theme={theme}>
                  <Separator size="small" />
                  <TextContainer>
                    <Text category="s1" status="primary">
                      {getUserFullName(users[userId])}
                    </Text>
                  </TextContainer>
                  <Separator size="small" />
                </SubheaderContainer>
                <Separator />
                <Grid container>
                  {activityByUser[userId]
                    .sort((a, b) => a.dateTime.localeCompare(b.dateTime))
                    .map((activityEntry, index, array) => (
                      <Grid item xs={4}>
                        <ConnectorContainer>
                          <Separator horizontal size="medium" />
                          <Dot theme={theme} />
                          {array.length > 1 && index < array.length - 1 && (
                            <>
                              <Separator horizontal size="medium" />
                              <StyledDivider />
                            </>
                          )}
                        </ConnectorContainer>
                        <Separator size="medium" />
                        <TextContainer>
                          <Text category="s2">{activityEntry.title}</Text>
                          <Separator size="small" />
                          <Text appearance="hint" category="s2">
                            {activityEntry.description}
                          </Text>
                        </TextContainer>
                      </Grid>
                    ))}
                </Grid>
                <Separator size={48} />
              </View>
            </PdfRow>
          ))}
        </>
      )}
    </>
  );
};

export default EmployeeActivityCard;
