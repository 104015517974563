import React, { useEffect, useState } from "react";
import styled from "styled-components/native";

import getDataUrl from "../../utils/getDataUrl";

interface Props {
  url: string;
}

const StyledImage = styled.Image`
  height: 100%;
  width: 100%;
`;

const CompanyLogoImage = ({ url }: Props) => {
  const [dataUrl, setDataUrl] = useState<string>();
  useEffect(() => {
    (async () => {
      try {
        setDataUrl(await getDataUrl(url));
      } catch (error) {
        // Do nothing
      }
    })();
  }, [url]);
  return (
    <>
      {Boolean(dataUrl) && (
        <StyledImage resizeMode="contain" source={{ uri: dataUrl }} />
      )}
    </>
  );
};

export default CompanyLogoImage;
