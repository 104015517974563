import { Grid } from "@material-ui/core";
import { Text, useTheme } from "@ui-kitten/components";
import dayjs from "dayjs";
import * as geolib from "geolib";
import React, { useContext } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

import { dateTimeFormat, mapDefaultZoom } from "../../constants";
import { DailyActivityReport } from "../../types";
import getUserFullName from "../../utils/getUserFullName";
import AspectRatioView from "../AspectRatioView";
import DefaultGoogleMapReact from "../DefaultGoogleMapReact";
import HeaderContainer from "../HeaderContainer";
import JobSummariesContext from "../JobSummariesContext";
import PdfRow from "../PdfRow";
import PersonDotMarker from "../PersonDotMarker";
import ReportMedia from "../ReportMedia";
import Separator from "../Separator";
import SubheaderContainer from "../SubheaderContainer";

interface DailyActivityReportCardProps {
  dailyActivityReport: DailyActivityReport;
}

const HeaderTextLineContainer = styled.View`
  align-items: baseline;
  flex-direction: row;
  justify-content: space-between;
`;

const MapContainer = styled.View`
  border-radius: 8px;
  overflow: hidden;
`;

const MapPadding = styled.View`
  height: 100%;
  padding: 8px;
`;

const TextContainer = styled.View`
  padding-left: 16px;
  padding-right: 16px;
`;

const ItalicsText = styled(Text)`
  font-style: italic;
`;

const DailyActivityReportCard = ({
  dailyActivityReport,
}: DailyActivityReportCardProps) => {
  const theme = useTheme();

  const { jobs, sites, users } = useContext(JobSummariesContext);
  const job = jobs[dailyActivityReport.jobId];
  const site = sites[job.siteId];

  const reportContainsMedia = dailyActivityReport.media.length > 0;

  return (
    <>
      <PdfRow position={reportContainsMedia ? "top" : undefined}>
        <View>
          <HeaderContainer theme={theme}>
            <Separator size="small" />
            <HeaderTextLineContainer>
              <TextContainer>
                <Text category="h6" status="control">
                  Daily Activity Report
                </Text>
              </TextContainer>
              <TextContainer>
                <Text status="control">
                  <Text category="s1" status="control">
                    Started at:
                  </Text>
                  {` ${dayjs(dailyActivityReport.started.at).format(
                    dateTimeFormat
                  )}`}
                </Text>
              </TextContainer>
            </HeaderTextLineContainer>
            <HeaderTextLineContainer>
              <TextContainer>
                <Text status="control">
                  <Text category="s1" status="control">
                    Submitted by:
                  </Text>
                  {` ${getUserFullName(users[dailyActivityReport.created.by])}`}
                </Text>
              </TextContainer>
              <TextContainer>
                <Text status="control">
                  <Text category="s1" status="control">
                    Completed at:
                  </Text>
                  {` ${dayjs(dailyActivityReport.completed?.at).format(
                    dateTimeFormat
                  )}`}
                </Text>
              </TextContainer>
            </HeaderTextLineContainer>
            <Separator size="small" />
          </HeaderContainer>
          <SubheaderContainer theme={theme}>
            <Separator size="small" />
            <TextContainer>
              <Text category="s1" status="primary">
                Summary
              </Text>
            </TextContainer>
            <Separator size="small" />
          </SubheaderContainer>
          <Grid container>
            <Grid item xs={9}>
              <Separator size="medium" />
              <TextContainer>
                {dailyActivityReport.summary ? (
                  <Text category="s2">{dailyActivityReport.summary}</Text>
                ) : (
                  <ItalicsText appearance="hint">
                    No summary available
                  </ItalicsText>
                )}
              </TextContainer>
              <Separator size="medium" />
            </Grid>
            <Grid item xs={3}>
              <MapPadding>
                <MapContainer>
                  <AspectRatioView height={9} width={16}>
                    <DefaultGoogleMapReact
                      defaultCenter={site.coordinates}
                      defaultZoom={mapDefaultZoom}
                      onGoogleApiLoaded={({ map, maps }) => {
                        const GoogleMapsMarker = new maps.Marker({
                          position: site.coordinates,
                        });
                        GoogleMapsMarker.setMap(map);

                        const {
                          maxLat,
                          maxLng,
                          minLat,
                          minLng,
                        } = geolib.getBounds([
                          site.coordinates,
                          dailyActivityReport.started.coordinates,
                        ]);
                        map.fitBounds({
                          east: maxLng,
                          north: maxLat,
                          south: minLat,
                          west: minLng,
                        });
                      }}
                    >
                      <PersonDotMarker
                        lat={dailyActivityReport.started.coordinates.lat}
                        lng={dailyActivityReport.started.coordinates.lng}
                      />
                    </DefaultGoogleMapReact>
                  </AspectRatioView>
                </MapContainer>
              </MapPadding>
            </Grid>
          </Grid>
        </View>
      </PdfRow>
      {reportContainsMedia && <ReportMedia media={dailyActivityReport.media} />}
    </>
  );
};

export default DailyActivityReportCard;
